@import "@/assets/scss/main.scss";

.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}

.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
}

.item-header {
  width: 100%;
  justify-content: space-between;
}

.item-title-wrapper {
  width: 50%;
}

.lang-tag {
  width: 5%;
}

.subtitles {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.item-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: capitalize;
}

.item-body {
  padding: 10px;
  background-color: $grey-1-mayday;
}
:deep() .element:first-child {
  margin-bottom: 10px;
}
.item-actions {
  width: 5%;
  cursor: pointer;
  color: $grey-5-mayday;
}

:deep() .el-collapse-item__header {
  border: none;
  height: 40px;
}

:deep() .el-collapse {
  border: none;
}

:deep() .el-collapse-item__wrap {
  border: none;
}

.default-tag {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $blue-mayday;
  font-weight: 700;
  font-size: 10px;
  line-height: 129.8%;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 2px 8px;
}

:deep() .el-popper {
  padding: 0px !important;
}

.action-icon {
  width: 14px;
  height: 14px;
}

.dropdown-item {
  cursor: pointer;
}
:deep() .dropdown-container {
  padding: 0px;
}
.text-input {
  height: 24.2px;
}
