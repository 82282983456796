@import "@/assets/scss/main.scss";

.analytics-download-header {
  background-color: white;
  padding: 15px;
  border-bottom: 2px solid $grey-4-mayday;
  overflow-x: auto;
  max-width: 100%;
}

.filter-icon {
  color: $grey-5-mayday;
  &:hover {
    color: $blue-mayday;
    cursor: pointer;
  }
}
.filter-icon-wrapper {
  width: 16px;
  height: 21px;
}

.routers {
  .router-wrapper {
    width: 77px;
    height: 34px;
  }
  .router-link-active {
    background-color: rgba($blue-mayday, 0.1);
    border-radius: 4px;
    color: $blue-mayday !important;
    padding: 5px;
  }
  .router-link {
    width: 100%;
    height: 100%;
    color: $grey-4-mayday;
  }
}
