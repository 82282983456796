@import "@/assets/scss/main.scss";

.workflow-logic {
  padding: 8px 0;
  &__logic {
    padding-bottom: 4px;
    &__title {
      padding: 4px;
    }
    &__rule {
      padding: 4px 0px;
    }
  }

  &__conditions {
    &__title {
      padding: 4px 4px 0px 4px;
    }
    &__rule {
      padding-bottom: 8px;
    }
    &__add {
      padding-left: 4px;
    }
  }
}
