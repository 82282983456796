$mayady-gray: #f2f4f9;

// --- IS ACTIVE BUTTONS --- \\
.menubar__button.is-active-text-color {
  background-color: red;
}

// --- TEXT COLOR --- \\\
.color-palette-item {
  height: 30px;
  width: 30px;
  margin: 2%;
  cursor: pointer;
}

// --- TABLE --- \\

// Table container
.tableWrapper {
  max-width: 100%;
  overflow: auto;
}

// Table cells and rows
.editor__content table td,
.editor__content table th {
  min-width: 1em;
  border: 1px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

// Resize cursor
.editor__content .resize-cursor {
  cursor: col-resize;
}

.editor__content table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

// --- CALLOUT --- \\
.editor__content .callout {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.7);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 2px;

  p {
    margin-bottom: 0 !important;
  }
}

// --- Emoji Picker --- \\
.emoji-picker {
  z-index: 1000;
  margin-top: 30px;
  position: absolute;
  background-color: white !important;
  max-width: 250px;
  border: 0px !important;
  border-radius: 1px;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.2),
    0 0 0 1px rgba(10, 10, 10, 0.2);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.2), 0 0 0 1px rgba(10, 10, 10, 0.2);

  .border {
    border: none !important;
  }
}

#Emojis {
  height: 195px;
}

.container-search input {
  background-color: #f9f7f7 !important;
}

#Categories {
  background-color: white !important;
}

#Categories::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

// --- Image Resizing --- \\
.editor_content img {
  // max-height: 100% !important;
  // max-width: 100% !important;
}