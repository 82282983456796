@import "@/assets/scss/main.scss";


.setting-menu {
  width: 100%;
}
.settings-menu-category {
  color: $grey-9-mayday;
  font-size: 14px;
  margin-bottom: 12px;
}
.settings-menu-category-title {
  margin-bottom: 8px;
  font-size: 12px;
  color: $grey-7-mayday;
}

.settings-menu-option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  border-left: 2px solid transparent;
  color: $grey-9-mayday;
  padding-left: 8px;

  &:hover {
    border-color: $grey-2-mayday;
  }

  &.active {
    border-color: $blue-mayday !important;
    font-weight: bold;

    .setting-menu-option-icon {
      display: none;
    }
    .setting-menu-option-active-icon {
      display: block;
    }
  }
}

.setting-menu-option-icon {
  margin-right: 8px;
}
.setting-menu-option-active-icon {
  color: $blue-mayday;
  display:none;
  margin-right: 8px;
}
