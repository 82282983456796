@import "@/assets/scss/main.scss";

.app-name {
  width: 250px;
}
.created-by-container {
  font-size: 12px;
  font-style: italic;
  color: $grey-7-mayday;
}

.small-input {
  :deep() .el-input--small {
    width: 100% !important;
  }
  :deep() .el-input__inner {
    letter-spacing: 2px;
  }
}

.input-label {
  font-weight: 700;
  color: black;
  font-size: 14px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.action-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.small-icon {
  width: 14px;
  height: 14px;
  color: $grey-7-mayday;

  &:hover {
    cursor: pointer;
    color: $red-mayday;
  }
}

.indicator-popconfirm {
  display: flex;
  align-items: center;
}

.jwt-lifetime-input {
  width: 100px;
  :deep() .el-input__inner {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    border-right: 0px;
  }
}
.jwt-lifetime-select {
  width: 130px;
  :deep() .el-input__inner {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    height: 32px;
  }
}
