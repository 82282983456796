@import "@/assets/scss/main.scss";

.range-field-label {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: $grey-9-mayday;
}

.range-underline {
  height: 1px;
  background-color: $grey-2-mayday;
  border: none;
  margin: 10px;
}

.el-button {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: $grey-5-mayday;
  border-color: $grey-5-mayday;
  background-color: $grey-1-mayday;
  margin-left: 0px;

  &.is-active {
    border-color: $blue-mayday;
    color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
  }
}
