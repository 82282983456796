@import "@/assets/scss/main.scss";

.workflow-set-context-from-resource-function {
  &__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &__item {
      flex: 12;
    }
    &__callout {
      padding: 0 4px;
      margin-bottom: 8px;
    }
    &__delete {
      flex: 1;
      margin-top: 16px;
      cursor: pointer;
      color: $grey-5-mayday;
      &:hover {
        color: $blue-mayday;
      }
    }
  }
  &__add {
    margin: 4px 4px;
  }
  &__divider {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0;
    &__title {
      padding: 0 4px;
      width: fit-content;
      white-space: nowrap;
      font-weight: bold;
      color: $grey-8-mayday;
    }
    &__line {
      margin: 0 4px;
      width: 100%;
      height: 1px;
      border: 1px solid $grey-3-mayday;
    }
  }
}
