@import "@/assets/scss/main.scss";

#invitation-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}

.text-underline {
  text-decoration: underline;
}

.dark-link {
  color: $unknown-blue-2-mayday;
}

.dark-link:hover {
  text-decoration: none;
}
