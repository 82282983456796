@import "@/assets/scss/main.scss";

.checkbox-button {
  border: 1px solid $grey-2-mayday;
  border-radius: 2px;
  padding: 4px 8px;
  &.is-checked {
    border-color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
    :deep() .el-checkbox__inner {
      border-radius: 2px;
      background-color: $blue-mayday;
      box-sizing: border-box;
      box-shadow: $shadow-sm-mayday;
      border: 1px solid $blue-mayday;
      &:after {
        display: none;
      }
    }
  }
}

.el-checkbox {
  margin-left: 0px !important;
  margin-right: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  :deep() .el-checkbox__label {
    font-size: 12px;
  }
}
