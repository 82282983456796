@import "@/assets/scss/main.scss";

.preference {
  .header {
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    justify-content: space-between;
    .info-group {
      display: flex;
      align-items: center;

      .info {
        .title {
          color: black;
          font-size: 16px;
          font-weight: bold;
        }
        .description {
          color: $grey-6-mayday;
          font-size: 12px;
        }
      }
    }
  }

  .rule-input {
    :deep() .el-input__inner {
      font-size: 12px;
      width: 48px;
      height: 24px;
      padding: 0;
      padding-left: 6px;
    }
    :deep() .el-input-group__append {
      padding: 0 6px;
    }
  }
  .is-error {
    :deep() .el-input__inner {
      border: 1px solid red !important;
    }
  }
}
