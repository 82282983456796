@import './editor';
@import './colors';
@import './typography';

body {
  overflow: auto
}

@media only screen and (max-device-width: 1024px) {
  .product-viewer-collapsable {
    height: 48px;
    cursor: pointer;
  }
}
// MOBILE GLOBAL SETTINGS
@media only screen and (max-device-width: 768px) {
  .display-3 {
    font-size: 2rem;
  }
  .product__result__link {
    margin: 16px 0px;
  }
  .collapse-section {
    border: 1px solid $grey-2-mayday;
    border-radius: 4px;
    margin-bottom: 4px;
    margin-top: 4px;
    background-color: $grey-1-mayday;
  }
  .knowledge-cases {
    margin-bottom: 12px;
  }
}

.product-viewer-collapsable {
  &:hover {
    .fa-group {
      color: $blue-mayday;
    }
  }
}

.cursor {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.modal-xxxl {
  max-height: 70vh !important;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.md-scrollbar-section {
  max-height: calc(100vh);
}

.analytics-container {
  max-height: calc(100vh);
}

.analytics-perf-scrollbar-container {
  max-height: 100vh;
  position: absolute;
}

// .dashboard-scrollbar-container {
//   max-height: calc(100vh - 60px);
//   position: absolute;
//   bottom: 0;
//   width: 100%;
// }

// .automations-scrollbar-container {
//   max-height: calc(100vh - 60px);
//   position: relative;
//   width: 100%;
// }

//////  CUSTOM SCROLLBAR  \\\\\\
// ::-webkit-scrollbar {
//   background: rgba(0, 0, 0, 0.2);
//   width: 11px;
// }

// ::-webkit-scrollbar-track {
//   background-color: #fff;
//   // background-color: red;
// }

// ::-webkit-scrollbar-track:hover {
//   background-color: #f4f4f4;
// }

// ::-webkit-scrollbar-thumb {
//   border: 3px solid rgba(0, 0, 0, 0);
//   background-clip: padding-box;
//   -webkit-border-radius: 8px;
//   background-color: rgba(0, 0, 0, 0.15);
// }

// ::-webkit-scrollbar-thumb:hover {
//   background-color: #cccccc;
//   border: 2px solid #cccccc;
// }

.hide-x-scroll {
  overflow-x: hidden !important;
}

.side-icon:active,
.side-icon:focus,
.side-icon,
.side-icon:active:focus,
.side-icon-selected:active,
.side-icon-selected:focus,
.side-icon-selected,
.side-icon-selected:active:focus,
.side-option:active,
.side-option:focus,
.side-option,
.side-option:active:focus,
.article-side-icon:active,
.article-side-icon:focus,
.article-side-icon,
.article-side-icon:active:focus {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.side-icon {
  max-width: 30px;
  cursor: pointer;
  margin: 10px;
  filter: invert(85%) sepia(12%) saturate(189%) hue-rotate(169deg)
    brightness(84%) contrast(88%);
}

.side-icon-selected {
  max-width: 30px;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid;
  margin: 10px;
  filter: invert(35%) sepia(38%) saturate(4730%) hue-rotate(192deg)
    brightness(98%) contrast(107%);
}

.side-icon:hover {
  filter: invert(44%) sepia(74%) saturate(5949%) hue-rotate(194deg)
    brightness(103%) contrast(99%);
}

.side-option-badge {
  sup {
    margin-top: 10px;
    margin-right: 10px;
  }
}

.tags-icon {
  font-size: 18px;
  max-width: 30px;
  cursor: pointer;
  margin: 10px;
}

.article-tag-icon-side:hover {
  color: $yellow-5-mayday;
}

.is-tags-diagnostics {
  filter: invert(44%) sepia(74%) saturate(5949%) hue-rotate(194deg)
    brightness(103%) contrast(99%);
}

.is-tags-articles {
  color: $yellow-5-mayday;
}

.folder-card-tags {
  margin-left: 20px;
}

// ---  TAGS CSS  ---  \\

.el-tag--plain {
  border-color: #0081f96c;
  color: $blue-5-mayday;

  .el-tag__close:hover {
    color: white !important;
    background-color: $blue-5-mayday !important;
  }
}

.el-tag--warning {
  border-color: $yellow-5-mayday !important;
  color: $yellow-5-mayday !important;

  .el-tag__close:hover {
    color: white !important;
    background-color: $yellow-5-mayday !important;
  }
}

*:focus {
  outline: none;
}

.mayday-editor__drag-options__wrapper {
  line-height: 1.2;
  min-width: 200px;
}
