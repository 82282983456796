@import "@/assets/scss/main.scss";

.entity-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $grey-4-mayday;
  padding: 8px;
}

.entity-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 1.5;
  color: black;
}
