@import "@/assets/scss/main.scss";

.public-knowledge-settings-list {
  width:100%;
  flex: 1;
  overflow-y: auto;
}
.subItem {
  background-color: white;
  border: 1px $grey-4-mayday solid;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 25px;
  min-width: 600px;
}

.subItem-header {
  margin-bottom: 10px;
}

.icon-wrapper {
  width: 28px;
  height: 28px;
  background-color: $grey-2-mayday;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.active {
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
}

.subItem-icon {
  width: 14px;
  height: 14px;
}

.subItem-title {
  font-family: Lato;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-align: left;
  font-weight: 600;
}
