@import "@/assets/scss/main.scss";

.private-knowledge-container {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
}

.private-knowledge-layout-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
  padding-left: 2px;
  padding-top: 2px;
}

.private-knowledge-layout {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  align-items: stretch;
  background-color: white;
  border-top-left-radius: 16px;
  box-shadow: $shadow-sm-mayday;
}

.private-knowledge-menu-wrapper {
  width: 280px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px 32px;
  border-right: $grey-2-mayday 1px solid;
}

.private-knowledge-wrapper {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
}
