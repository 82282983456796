@import "@/assets/scss/main.scss";

.private-header {
  padding: 16px 16px 16px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  flex:none;
  width: 100%;
}

.select-wrapper {
  min-width: fit-content;
  width: 250px;
}

.icon-wrapper {
  width: 32px;
  height: 32px;
  background-color: rgba($blue-mayday, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: 14px;
  height: 16px;
  color: $blue-mayday;
}

.menu-divider {
  height: 34px;
}

.el-dropdown-link:hover {
  cursor: pointer;
  color: $blue-mayday;
}

.dropdown-links {
  color: $grey-7-mayday;
}

:deep() .dropdown-links:hover {
  .el-dropdown-menu__item {
    color: $blue-mayday !important;
  }
}

:deep() .router-link-active {
  .el-dropdown-menu__item {
    color: $blue-mayday !important;
  }
}
