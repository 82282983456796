@import "@/assets/scss/main.scss";

.title-wrapper {
  display: flex;
  justify-content: space-between;

  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    color: black;
  }

  .reset-link {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: $grey-6-mayday;
    cursor: pointer;
  }
}
