@import "@/assets/scss/main.scss";

.text-input {
  font-size: 12px;
}
.char-count {
  font-size: 12px;
  color: $grey-6-mayday;
}
:deep() .el-textarea.is-disabled .el-textarea__inner {
  color: $grey-7-mayday;
}
