@import "@/assets/scss/main.scss";

.small-link {
  color: $grey-6-mayday;
}

.small-link:hover {
  color: $blue-7-mayday;
}

.highlight {
  color: $blue-mayday;
}
