@import "@/assets/scss/main.scss";

.sso-attributes-mapping__profile-mgr {
  &__header {
    display: flex;
    margin: 8px 0px;
    &__title {
      padding: 0px;
      font-size: 14px;
      font-weight: bold;
      &-icon {
        display: flex;
        justify-content: center;
        width: 34px;
      }
    }
  }
  &__items-wrapper {
    display: flex;
    flex-direction: column;
    &__item {
      display: flex;
      margin: 4px 0px;
      padding: 0px;

      &-icon,
      &-cta {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
      }

      &-cta {
        visibility: hidden;

        &__icon {
          cursor: pointer;
          &:hover {
            color: $blue-mayday;
          }

          &-error {
            &:hover {
              color: $red-mayday;
            }
          }
        }
      }

      &-editable > &-cta {
        visibility: initial;
      }

      &:hover > &-cta {
        visibility: initial;
      }
    }
  }
  &__add-btn {
    margin: 4px 0px;
  }
}
