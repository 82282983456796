@import "@/assets/scss/main.scss";

.download-icon-wrapper:hover {
  cursor: pointer;
  .download-icon {
    color: $blue-mayday;
  }
}
.address-link {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-style {
  width: 100%;
  padding: 0px 10px;
  background-color: $body-bg;
}
