@import "@/assets/scss/main.scss";

.workflow-logic-rule {
  display: flex;
  flex-direction: row;

  &__key {
    flex: 8;
  }

  &__operator {
    flex: 6;
  }

  &__value {
    flex: 6;
  }

  &__remove {
    flex: 1;
    align-self: center;
    &:hover {
      color: $red-mayday;
      cursor: pointer;
    }
  }
}
