@import "@/assets/scss/main.scss";

#reset-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}

.title {
  line-height: 1.2 !important;
}

.dark-link {
  color: $unknown-blue-2-mayday;
}

.dark-link:hover {
  text-decoration: none;
}

.has-success:after,
.has-danger:after {
  display: none;
}

.has-success {
  color: rgba($blue-mayday, 0.4);
}

.has-danger {
  color: rgba($red-mayday, 0.4);
}
