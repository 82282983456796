@import "@/assets/scss/main.scss";

.sso-btn {
  width: 100%;
  box-shadow: $shadow-mayday;
}
.sso-btn img {
  width: 20px;
  height: 20px;
}
.sso-btn .connect-with-text {
  padding-left: 16px;
  font-size: 14px;
}

.sso-icon {
  font-size: 20px;
}
