@import "@/assets/scss/main.scss";

.icon {
  width: 12px;
  height: 12px;
}

.radio-group {
  display: flex;
  flex-direction: column;

  .radio-button {
    border: 1px solid $grey-2-mayday;
    border-radius: 2px;
    padding: 10px 10px;
    &.is-checked {
      border-color: $blue-mayday;
      background-color: rgba($blue-mayday, 0.1);
    }
  }
}

.el-radio {
  margin-left: 0px !important;
  margin-right: 0px;
  margin-top: 6px;
  margin-bottom: 6px;

  :deep() .el-radio__label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    color: $grey-9-mayday;
    height: 24px;
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin-block: -4px;
    justify-content: space-between;
    width: 100%;
    padding-left: 12px;
    padding-right: 16px;
  }
}

.default-tag {
  background-color: rgba($blue-mayday, 0.1);
  border: 1px solid $blue-mayday;
  box-sizing: border-box;
  border-radius: 2px;
  color: $blue-mayday;
  font-weight: bold;
  font-size: 10px;
  line-height: 129.8%;
  text-align: center;
  letter-spacing: 0.5px;
}
