@import "@/assets/scss/main.scss";

#reset-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}

.title {
  line-height: 1.2 !important;
}

.link {
  border: none;
  background: none;
  color: $blue-mayday;
}

.link:hover {
  color: $unknown-blue-2-mayday;
}

.reset-button {
  min-width: 150px;
}

.dark-link {
  color: $unknown-blue-2-mayday;
}
