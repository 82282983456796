@import "@/assets/scss/main.scss";

.text-copy-input {
  font-size: 12px;
  height: 24.2px;
}
.copy-button {
  width: 100px;
}
:deep() .el-input .el-input__inner {
  height: 100%;
}
