// Blues
$blue-0_5-mayday: #eaf5ff;
$blue-1-mayday: #c7e3fe;
$blue-2-mayday: #92c9fd;
$blue-3-mayday: #55abfb;
$blue-4-mayday: #2b96fa;
$blue-5-mayday: #0081f9;
$blue-6-mayday: #006fd7;
$blue-7-mayday: #005db4;
$blue-8-mayday: #004c92;
$blue-9-mayday: #003a6f;

$blue-mayday: $blue-5-mayday;

$unknown-blue-1-mayday: #525f7f;
$unknown-blue-2-mayday: #00407c;
$unknown-blue-3-mayday: #172b4d;

// Greys
$grey-0_5-mayday: #f9fbff;
$grey-1-mayday: #f2f5f9; // #f9fbff; // #f6f9fc
$grey-2-mayday: #d5dae3; // #f2f5f9; // #e9ecef
$grey-3-mayday: #b8bfcd; // #d5dae3; // #dee2e6
$grey-4-mayday: #9aa4b6; // #b8bfcd; // #ced4da
$grey-5-mayday: #7d89a0; //  #9aa4b6; // #adb5bd
$grey-6-mayday: #677184; // #7d89a0; // #8898aa
$grey-7-mayday: #525a68; // #677184; // #828282
$grey-8-mayday: #3c424c; // #525a68; // #4f4f4f
$grey-9-mayday: #262a30; // #3c424c; // #333333

// Reds
$red-1-mayday: #fff1f2; // #ffa2ba;
$red-2-mayday: #ffdee1; // #fe799d;
$red-3-mayday: #f698a3; // #fe517f;
$red-4-mayday: #ed5264; // #fd2862;
$red-5-mayday: #e40c26; // #fd0044;
$red-6-mayday: #cc0a22; // #d40039;
$red-7-mayday: #b4081d; // #ab002e;
$red-8-mayday: #9c0618; // #830023;
$red-9-mayday: #830413; // #5a0018;

$red-mayday: $red-5-mayday;

// Greens
$green-1-mayday: #ebfaf2; // #dfead5;
$green-2-mayday: #c4e5d4; // #cadcba;
$green-3-mayday: #9dcfb5; // #b6cf9f;
$green-4-mayday: #76ba97; // #a1c184;
$green-5-mayday: #4fa478; // #8cb369;
$green-6-mayday: #3c936a; // #789a59;
$green-7-mayday: #28815b; // #64814a;
$green-8-mayday: #146f4c; // #4f673a;
$green-9-mayday: #005d3d; // #3b4e2b;

$green-mayday: $green-5-mayday;

// Yellows
$yellow-1-mayday: #fff6e5; // #fef2db;
$yellow-2-mayday: #ffecbe; // #fee5b6;
$yellow-3-mayday: #ffe398; // #fdd992;
$yellow-4-mayday: #ffd973; // #fdcc6d;
$yellow-5-mayday: #ffd04d; // #fcbf49;
$yellow-6-mayday: #cfa83e; // #e1a93b;
$yellow-7-mayday: #9f7f2e; // #c7922d;
$yellow-8-mayday: #6f571f; // #ac7c1e;
$yellow-9-mayday: #3d2d0f; // #926510;

$yellow-mayday: $yellow-5-mayday;

// Pinks
$pink-mayday: $red-3-mayday;

// Purples
$purple-1-mayday: #f6f2ff; // #e8e1ff;
$purple-2-mayday: #e7ddff; // #fbf9ff;
$purple-3-mayday: #b8a2f5; // #ede6fe;
$purple-4-mayday: #8967ea; // #8f67ff;
$purple-5-mayday: #5a2ce0; // #733df9;
$purple-6-mayday: #551ddd;
$purple-7-mayday: #4f0ed9;
$purple-8-mayday: #4307c3;
$purple-9-mayday: #3700ac; // #2a0e70;

$purple-mayday: $purple-5-mayday;

// Body
$body-bg: $grey-0_5-mayday;
$body-color: $grey-9-mayday;

// Shadows
$shadow-sm-mayday: 0px 0px 2px 0px #7090b01a, 0px 2px 4px 0px #7090b026;
$shadow-mayday: 0px 0px 2px 0px #7090b033, 0px 8px 16px 0px #7090b040;
$shadow-lg-mayday: 0px 0px 8px 0px #7090b040, 0px 14px 28px 0px #7090b040;

:export {
  blueMayday: $blue-mayday;
  redMayday: $red-mayday;
  greenMayday: $green-mayday;
  yellowMayday: $yellow-mayday;
  purpleMayday: $purple-mayday;
  pinkMayday: $pink-mayday;

  // GREENS
  greenMayday: $green-mayday;
  green1Mayday: $green-1-mayday;
  green2Mayday: $green-2-mayday;
  green3Mayday: $green-3-mayday;
  green4Mayday: $green-4-mayday;
  green5Mayday: $green-5-mayday;
  green6Mayday: $green-6-mayday;
  green7Mayday: $green-7-mayday;
  green8Mayday: $green-8-mayday;
  green9Mayday: $green-9-mayday;

  // Yellows
  yellow1Mayday: $yellow-1-mayday;
  yellow2Mayday: $yellow-2-mayday;
  yellow3Mayday: $yellow-3-mayday;
  yellow4Mayday: $yellow-4-mayday;
  yellow5Mayday: $yellow-5-mayday;
  yellow6Mayday: $yellow-6-mayday;
  yellow7Mayday: $yellow-7-mayday;
  yellow8Mayday: $yellow-8-mayday;
  yellow9Mayday: $yellow-9-mayday;

  //GREYS
  grey1Mayday: $grey-1-mayday;
  grey2Mayday: $grey-2-mayday;
  grey3Mayday: $grey-3-mayday;
  grey4Mayday: $grey-4-mayday;
  grey5Mayday: $grey-5-mayday;
  grey6Mayday: $grey-6-mayday;
  grey7Mayday: $grey-7-mayday;
  grey8Mayday: $grey-9-mayday;
  grey9Mayday: $grey-8-mayday;

  // REDS
  red1Mayday: $red-1-mayday;
  red2Mayday: $red-2-mayday;
  red3Mayday: $red-3-mayday;
  red4Mayday: $red-4-mayday;
  red5Mayday: $red-5-mayday;
  red6Mayday: $red-6-mayday;
  red7Mayday: $red-7-mayday;
  red8Mayday: $red-8-mayday;
  red9Mayday: $red-9-mayday;

  // PURPLES
  purple1Mayday: $purple-1-mayday;
  purple2Mayday: $purple-2-mayday;
  purple3Mayday: $purple-3-mayday;
  purple4Mayday: $purple-4-mayday;
  purple5Mayday: $purple-5-mayday;
  purple9Mayday: $purple-9-mayday;

  // SHADOWS
  shadowSmMayday: $shadow-sm-mayday;
  shadowMayday: $shadow-mayday;
  shadowLgMayday: $shadow-lg-mayday;
}
