@import "@/assets/scss/main.scss";

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title-container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-block: 8px;
  &.divider {
    border-bottom: 1px solid $grey-2-mayday;
  }
  &.reverse {
    flex-direction: row-reverse;
  }
}

.content-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.dropdown-container {
  padding-left: 32px;
  padding-block: 8px;
  border-bottom: 1px solid $grey-4-mayday;
}
.chevron-icon {
  width: 12px;
  height: 12px;
  margin: 4px;
  transition: transform 0.3s;
}
.open-button {
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: $grey-1-mayday;
  }
  &.opened {
    .chevron-icon {
      transform: rotate(90deg);
    }
  }
}
