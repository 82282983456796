@import "@/assets/scss/main.scss";

.passwordInvalid {
  border: 1px solid $red-mayday;
}

.register-button {
  min-width: 150px;
}

.has-success:after,
.has-danger:after {
  display: none;
}

.has-success {
  color: rgba($blue-mayday, 0.4);
}

.has-danger {
  color: rgba($red-mayday, 0.4);
}
