@import "@/assets/scss/main.scss";

:deep(.el-color-picker) {
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.el-color-picker__trigger) {
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
}

:deep(.el-color-picker__color) {
  border-radius: 50%;
  border: none;
}

:deep(.el-color-picker__color-inner) {
  border-radius: 50%;
}

.placeholder-value {
  font-size: 12px;
  color: $grey-6-mayday;
}
