@import "@/assets/scss/main.scss";

#login-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}
.title {
  line-height: 1.2 !important;
}

.md-divider {
  :deep() .el-divider__text {
    background-color: $grey-1-mayday;
    color: $grey-6-mayday;
  }
}

.md-divider {
  :deep() .el-divider {
    margin: 12px 0;
  }
}
