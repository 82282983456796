@import "@/assets/scss/main.scss";

.element:hover {
  :deep() .display-on-hover {
    display: block;
  }
}
.element-title {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  font-weight: bold;
}
.element-description {
  font-size: 12px;
  color: $grey-7-mayday;
  white-space: pre-line;
}

.horizontal-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.state-info {
  font-size: 12px;
}

.icon-state-info {
  color: $grey-4-mayday;
}

.active-green {
  color: $green-mayday !important;
}
.active-yellow {
  color: $yellow-mayday !important;
}

.subItem {
  background-color: white;
  border: 1px $grey-4-mayday solid;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 25px;
  min-width: 600px;
}
