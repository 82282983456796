@import "@/assets/scss/main.scss";

.step {
  display: flex;
  align-items: center;
  padding-bottom: 8px;

  .title {
    margin-left: 10px;
    color: black;
    font-size: 16px;
    font-weight: bold;
  }
}
