@import "@/assets/scss/main.scss";

.sso-attributes-mapping-input {
  width: 100%;
  display: flex;
  align-items: center;
  &-editable {
    width: 100%;
    :deep() .el-input--md {
      height: 30px;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        padding: 0px 8px;
      }
    }
  }

  &-readonly {
    padding-left: 8px;
    font-size: 14px;
    word-break: break-all;
  }
}
