@import "@/assets/scss/main.scss";

.workflow-callout {
  display: flex;
  flex-direction: row;
  padding: 8px;
  border-radius: 6px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  align-items: center;
  justify-content: space-between;

  &__main {
    color: var(--color);
    &__title {
      font-weight: bold;
      font-size: 12px;
    }
    &__description {
      font-size: 12px;
    }
  }
}
