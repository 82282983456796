@import "@/assets/scss/main.scss";

.date-filter-field-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .el-date-editor {
    :deep() .el-input__inner {
      border: none;
      cursor: pointer;
      text-align: right;
    }
    :deep() .el-input__prefix {
      display: none;
    }
  }

  .date-icon {
    margin-left: 12px;
    height: 16px;
  }
}
