@import "@/assets/scss/main.scss";

.empty-state-container {
  margin-top: 120px;
}
.empty-state-img {
  height: 100px;
}

.empty-state-txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 30px;
}

.empty-state-subtitle {
  font-size: 14px;
  color: $grey-6-mayday;
}
