@import "@/assets/scss/main.scss";

.logout-view {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.logout-view-icon {
  font-size: 5rem;
  color: $blue-mayday;
}
