@import "@/assets/scss/main.scss";

.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.subcontent {
  border: 1px solid $grey-3-mayday;
  border-radius: 2px;
  height: 75%;
}
