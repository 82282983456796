@import "@/assets/scss/main.scss";

.checkboxes-container {
  display: flex;
  width: 100%;
  max-width: 270px;
  justify-content: space-between;
  align-items: flex-start;
}
.checkbox-button {
  display: flex;
  width: 70px;
  flex-direction: column-reverse;
  align-items: center;
  gap: 4px;
  margin: 0px;
  margin-right: 12px;
  :deep() .el-checkbox__label {
    color: black;
    padding: 0px;
  }
  &.is-checked {
    :deep() .el-checkbox__inner {
      border-radius: 2px;
      background-color: $blue-mayday;
      box-sizing: border-box;
      box-shadow: $shadow-sm-mayday;
      border: 1px solid $blue-mayday;
      &:after {
        display: none;
      }
    }
  }
}
