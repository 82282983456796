@import "@/assets/scss/main.scss";

.item {
  background-color: white;
  border: 1px $grey-2-mayday solid;
  border-radius: 4px;
  padding: 14px;
  margin-bottom: 25px;
}

.item-header {
  margin-bottom: 10px;
}

.icon-wrapper {
  width: 28px;
  height: 28px;
  background-color: $grey-1-mayday;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.active {
  background-color: rgba($blue-mayday, 0.1);
  color: $blue-mayday;
}

.item-icon {
  width: 14px;
  height: 14px;
}

.item-title {
  font-family: Lato;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.02em;
  text-align: left;
  font-weight: 600;
}
