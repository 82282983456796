@import "@/assets/scss/main.scss";

.base-icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;

  &-sm {
    width: 22px;
    height: 22px;
    font-size: 14px;
    border-radius: 4px;
  }

  &-tertiary {
    background-color: transparent;
    color: $grey-9-mayday;
    &:hover {
      background-color: rgba($grey-5-mayday, 0.15);
    }
  }
}
